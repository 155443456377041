<template>
    <div>
        <Menu/>

        <el-row style="height: 61px">
            <el-col :span="24"><div class="grid-content "></div></el-col>
        </el-row>

        <el-calendar v-model="value">
            <template slot="dateCell" slot-scope="{date, data}">
                <p :class="data.isSelected ? 'is-selected' : ''">
                    {{ data.day.split('-').slice(2).join('-') }}
                    {{ data.isSelected ? '✔️' : ''}}
                </p>
            </template>
        </el-calendar>

        <Footer/>
    </div>
</template>

<script>
    import Menu from "../components/menu";
    import Footer from "../components/footer";
    export default {
        name: "schedule",
        components: {Footer, Menu},
        created() {
            this.global.menunums = "5"
        },
        data() {
            return {
                value: new Date()
            }
        }
    }
</script>

<style scoped>
    .is-selected {
        color: #1989FA;
    }
    .isnselected {
        color: #fa820f;
    }
</style>