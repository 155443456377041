import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global from "@/store/global.js"
Vue.config.productionTip = false

//element-ui的依赖
import Element from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
Vue.use(Element)

//axios的依赖
import axios from 'axios'
Vue.prototype.$axios = axios

//全局变量的依赖
Vue.prototype.global = global


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
