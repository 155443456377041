<template>
    <div class="custom-bg">
        <Menu/><!--<Menu msg="Welcome to Your Vue.js App"/>-->

        <el-container>
            <el-main>
                <el-row>
                    <el-col :span="24"><div class="grid-content "></div></el-col>
                </el-row>
                <el-row :gutter="20" style="color: #3f0011; font-size: small">
                    <el-col :span="2"><div class="grid-content "></div></el-col>
                    <el-col :span="10"><div class="grid-content ">在线服务</div></el-col>
                    <el-col :span="10"><div class="grid-content ">设计制造</div></el-col>
                    <el-col :span="2"><div class="grid-content "></div></el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="2"><div class="grid-content "></div></el-col>
                    <el-col :span="10"><el-link style="color: #701996; font-size: large" href="http://w.bibx.cn">工作空间"可自定义网址页"</el-link></el-col>
                    <el-col :span="10"><div style="color: #e0dce6; font-size: large">物联网产品定制</div></el-col>
                    <el-col :span="2"><div class="grid-content "></div></el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="2"><div class="grid-content "></div></el-col>
                    <el-col :span="10"><div style="color: #e0dce6; font-size: large">记工汇算</div></el-col>
                    <el-col :span="10"><div style="color: #e0dce6; font-size: large">专用控制器定制</div> </el-col>
                    <el-col :span="2"><div class="grid-content "></div></el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="4"><div class="grid-content "></div></el-col>
                    <el-col :span="4"><div style="color: #e0dce6; font-size: large"></div> </el-col>
                    <el-col :span="4"><div style="color: #e0dce6; font-size: large"></div> </el-col>
                    <el-col :span="4"><div class="grid-content "></div></el-col>
                </el-row>
            </el-main>
        </el-container>

        <el-carousel height="335px" direction="horizontal" :autoplay="true" :interval="3000">
<!--            <el-carousel-item>-->
<!--                <img src="../assets/gq.png">-->
<!--            </el-carousel-item>-->
            <el-carousel-item>
                <img src="../assets/dd.png">
            </el-carousel-item>
<!--            <el-carousel-item>-->
<!--                <img src="../assets/middle.png">-->
<!--            </el-carousel-item>-->
<!--            <el-carousel-item>-->
<!--                <img src="../assets/pt.png">-->
<!--            </el-carousel-item>-->
<!--            <el-carousel-item>-->
<!--                <img src="../assets/by.png">-->
<!--            </el-carousel-item>-->
            <el-carousel-item>
                <img src="../assets/cc.png">
            </el-carousel-item>
        </el-carousel>

        <Footer/>
    </div>
</template>

<script>
    import Menu from "../components/menu";
    import Footer from "../components/footer";
    export default {
        name: "main",
        components: {Footer, Menu},
        methods:{
            handleClick(key) {
                if(this.global.menunums!==key) {
                    if (key == "5") {
                        // console.log(new Date())
                        this.global.menunums = "5"
                        this.$router.replace({name: 'schedule'})
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .el-carousel {
        height: 400px; /* 设置轮播图的高度 */
        width: 100%; /* 设置轮播图的宽度 */
        border-radius: 10px; /* 设置轮播图的圆角 */
    }
    .el-carousel-item {
        display: flex; /* 设置轮播项为 Flex 布局 */
        justify-content: center; /* 设置轮播项水平居中 */
        align-items: center; /* 设置轮播项垂直居中 */
        height: 100%; /* 设置轮播项的高度 */
    }
    .el-carousel-item img {
        width: 100%; /* 设置图片宽度为 100% */
        height: auto; /* 设置图片高度自适应 */
    }

    .el-row {
        margin-bottom: 20px;
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        background: #d3dce6;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .custom-bg {
        background-color: rgba(254, 223, 226, 0.61);
        /*background-image: url('../assets/bk6.jpg');*/
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
    }
</style>