<template>
    <div id="bmsg">
        <div style="color: #d0c896">泰安市泰山景区智控信息技术工作室</div>
        ©2023 泰山智控 <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2023029980号</a>
    </div>
</template>

<script>
    export default {
        name: "mfooter"
    }
</script>

<style scoped>
    #bmsg{
        position: fixed;
        bottom: 0;
        font-size: 16px;
        color: #999;
        width: 100%;
        text-align: center;
        background-color: #EDEDED;
    }
    a{
        color: #9BCD9B;
        text-decoration: none;
    }
</style>