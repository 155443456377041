<template>
    <div>
        <Menu/>
        <el-row style="height: 50px">
            <el-col :span="24"><div class="grid-content "></div></el-col>
        </el-row>

        <el-container>
            <el-main>
                <el-row>
                    <el-col :span="24"><div class="grid-content "><img src="../assets/logo.png"></div></el-col>
                </el-row>

                <el-row  :gutter="30">
                    <el-col :span="4">
                        <div class="grid-content  rightop">
                            <h4>简介</h4>
                        </div>
                    </el-col>
                    <el-col :span="16">
                        <div class="grid-content leftop ">
                            <p> 泰安市泰山景区智控信息技术工作室，既“泰山智控”。
                                工作室于2022年9月登记设立，开展技术服务、技术开发、技术推广等业务。
                                具备电机及其控制系统研发、软件开发、计算机软硬件及其外围设备的设计制造能力。</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content">
                            .
                        </div>
                    </el-col>
                </el-row>

                <el-row  :gutter="30">
                    <el-col :span="4">
                        <div class="grid-content  rightop">
                            <h4>使命愿景</h4>
                        </div>
                    </el-col>
                    <el-col :span="16">
                        <div class="grid-content leftop ">
                            <p>我们有能力为传统设备工具开发可靠的控制系统和信息系统，有经验为产品提供智能化和信息化升级方案。把握创新机遇，创造更高价值。</p>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content">
                            .
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24"><div class="grid-content "> </div></el-col>
                </el-row>
                <el-row  :gutter="30">
                    <el-col :span="4">
                        <div class="grid-content  rightop">
                            <h4>联系我们</h4>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content leftop ">
                            <p>
                                电话：153 1548 4241<br>
                                微信：taishanzhikong<br>
                                邮箱：guorui@bibx.cn<br>
                                <br>
                                <el-link style="color: #701996; font-size: large" href="https://work.weixin.qq.com/ca/cawcde0d332f63c33a">企业微信·点击添加</el-link>
                            </p>

                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content leftop">
                            <img src="../assets/wx.png">
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content leftop">
                            <img src="../assets/qrcode.png">
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div class="grid-content">

                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>

        <Footer/>
    </div>
</template>

<script>
    import Menu from "../components/menu";
    import Footer from "../components/footer";
    export default {
        name: "about",
        components: {Footer, Menu},
        created() {
            this.global.menunums = "6"
        }
    }
</script>

<style scoped>
    .sit {
        height: 61px;
    }
    .main{
        text-align: center;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .rightop{
        text-align: right;
    }
    .leftop{
        text-align: left;
    }
</style>

<!--为智能化和信息化建设_添砖加瓦：传统的设备和工具仍有极大的进步空间，同时 微控制系统技术和信息技术已趋于成熟。
                                在工艺革新的滚滚大势下，我们相信且有能力为传统设备工具开发出智慧的控制系统和可靠的信息系统。把握创新机遇，
                                创造出我们与客户的共同价值。-->