<template>
  <div>
    <el-menu
            :default-active="menunums"
            class="custom-menu"
            mode="horizontal"
            @select="handleSelect"
            active-text-color="#ed1c24">

      <el-menu-item>
        <img src="../assets/logomins.png" alt="logo" />
      </el-menu-item>

      <el-menu-item index="1">首页</el-menu-item>

      <el-submenu index="2" disabled>
        <template slot="title">服务</template>
        <el-submenu index="21">
          <template slot="title">线上服务</template>
          <el-menu-item index="211">记工助手</el-menu-item>
          <el-menu-item index="212">记账助手</el-menu-item>
          <el-menu-item index="213">络合笔记</el-menu-item>
        </el-submenu>
        <el-submenu index="22">
          <template slot="title">工程服务</template>
          <el-menu-item index="221">软件与应用开发</el-menu-item>
          <el-menu-item index="222">专用控制器研发</el-menu-item>
          <el-menu-item index="223">物联网系统研发</el-menu-item>
        </el-submenu>
      </el-submenu>

      <el-submenu index="3" disabled>
        <template slot="title">案例</template>
        <el-menu-item index="31">客户定制产品</el-menu-item>
        <el-menu-item index="32">独立研发产品</el-menu-item>
      </el-submenu>

      <el-submenu index="4" disabled>
        <template slot="title">资料</template>
        <el-menu-item index="41">商业产品</el-menu-item>
        <el-menu-item index="42">公益产品</el-menu-item>
      </el-submenu>

      <el-menu-item index="5" disabled>
        日程
      </el-menu-item>

      <el-menu-item index="6" >关于</el-menu-item>
    </el-menu>
  </div>
</template>

<!--      <el-menu-item index="5"><a href="https://bibx.cn" target="_blank" style="text-decoration: none">关于</a></el-menu-item>-->


<script>
export default {
  name: 'menu',
  props: {
     msg: String
  },
  data() {
    return {
      menunums:this.global.menunums,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key);
      if(this.global.menunums!==key){
        if(key=="1"){
          this.global.menunums="1"
          this.$router.replace({name:'main'})
        }
        if(key=="5"){
          this.global.menunums="5"
          this.$router.replace({name:'schedule'})
        }
        if(key=="6"){
          this.global.menunums="6"
          this.$router.replace({name:'about'})
        }
      }
    }
  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-menu {
    height: 61px; /* 设置el-menu的高度 */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .my-submenu {
    width: 200px; /* 设置宽度为200像素 */
  }
</style>
