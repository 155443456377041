import Vue from 'vue'
import VueRouter from 'vue-router'
import main from "@/views/main";
import about from "@/views/about";
import schedule from "@/views/schedule";

import mMain from "@/views/mMain";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component:main
  },
  {
    path: '/main',
    name: 'main',
    component:main
  },
  {
    path: '/schedule',
    name: 'schedule',
    component:schedule
  },
  {
    path: '/about',
    name: 'about',
    component: about
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/mMain',
    name: 'mMain',
    component:mMain
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
