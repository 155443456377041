<template>
    <div>
        <div class="alertboxmobile">
            <img src="../assets/logo.png">
            <h2>Mtit 泰山智控</h2>
<!--            <h3>暂不支持移动端访问</h3>-->
<!--            <h1>请使用PC浏览器访问</h1>-->
            <br>
            <br>
            <br>
            <br>

            <div>
                <h3>联系我们</h3>
                <p>
                    电话：153 1548 4241<br>
                    微信：taishanzhikong<br>
                    邮箱：guorui@bibx.cn<br>
                    <br>
                    <el-link style="color: #701996; font-size: large" href="https://work.weixin.qq.com/ca/cawcde0d332f63c33a">企业微信·点击添加</el-link>
                </p>
            </div>
        </div>


        <Mfooter/>
    </div>
</template>

<script>
    import Mfooter from "../components/mfooter";
    export default {
        name: "mMain",
        components: {Mfooter}
    }
</script>

<style scoped>
    .alertboxmobile {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 999;
    }
</style>